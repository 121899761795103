/**
 * Foursource page
 * https://foursource.com/baycity-x-foursource
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/baycity-x-foursource";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import Text from "../components/text";
import IconAnimation from "../components/iconAnimation";
import TitleSeparator from "../components/titleSeparator";

class mangoPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  render() {
    return (
      <>
        <Layout pageName="baycity-x-foursource" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className="paddingSidesBig pt-30 pb-30 text-center">
            <Text text={this.text.firstText}></Text>
          </div>
          <div className="topBottomDiagonal backgroundGrey paddingSidesBig pt-100 pb-100">
            <div className="text-center">
              <TitleSeparator info={this.text.startedProcessTitle}></TitleSeparator>
            </div>
            <div className="row row-cols-lg-4 row-cols-md-4 row-cols-sm-1 row-cols-1">
              {this.text.startedProcess.map((item, i) => (
                <div className="text-center" key={i}>
                  <IconAnimation info={item}></IconAnimation>
                </div>
              ))}
            </div>
          </div>
          <div className="paddingSidesBig pt-30 pb-30 text-center">
            <TitleSeparator info={this.text.whatIs}></TitleSeparator>
          </div>
          <div className="paddingSidesBig pt-30 pb-75 text-center">
            <TitleSeparator info={this.text.agencyPolicy}></TitleSeparator>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(mangoPage);
